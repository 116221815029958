import { mapState, mapGetters } from 'vuex'

export default {
    name: 'JsfList',
    data: () => ({
        jsftable: {
            sortBy: ['visualdate'],
            itemsPerPage: 15,
        },
        recsdisplayed: 0,
        asklock: false,
        lockitem: {},
        headers: [
            //{ text: 'Created', value: 'dcreatedts' },
            //{ text: 'Visual Date', value: 'visualts' },
            { text: 'JSF', value: 'jsfnumber' },
            { text: 'Created', value: 'created' },
            { text: 'Program Name', value: 'title' },
            { text: 'Area', value: 'beautyarea' },
            { text: 'Brand', value: 'brand' },
            { text: 'Type', value: 'promotiontype' },
            { text: 'Buyer', value: 'buyer' },
            { text: 'Visual Date', value: 'visualdate' },
            { text: 'Dept', value: 'dep' },
            { text: 'Mic', value: 'mic' },
            { text: 'Stores', value: 'numstores' },
            { text: 'Action', value: 'actionmenu', sortable: false, width: '10%' },
            { text: 'AS', value: 'artstatus' },
            { text: 'PS', value: 'status' },
            { text: 'CS', value: 'contractstatus' },
        ],
        listtab: null,
        artworkstat: [
            { text: 'All', value: 'all' },
            { text: 'Art Received', value: 'ArtReceived' },
            { text: 'Mockup Sent', value: 'MockupSent' },
            { text: 'Art Approved', value: 'ArtApproved' },
        ],
        artstatsel: 'all',
    }),
    mounted() {
        let worktabname = localStorage.getItem('tabvalue'),
            workrequestfor = localStorage.getItem('requestfor'),
            worktablestate = localStorage.getItem('jsftable'),
            worksearch = localStorage.getItem('jsfsearch')

        if (worktabname) {
            this.listtab = worktabname
        } else {
            this.listtab = 'all'
            this.tabname = 'all'
        }

        if (workrequestfor) {
            this.jsfrequestforsel = workrequestfor
        } else {
            this.jsfrequestforsel = 'All'
        }

        if (worktablestate) {
            this.jsftable = JSON.parse(worktablestate)
        }

        if (worksearch) {
            this.search = worksearch
            this.artstatsel = worksearch
        }

        if (this.search === 'null') {
            this.search = ''
        }

        this.refresh()
    },
    computed: {
        ...mapGetters('Sgs', ['getLoggedIn', 'getUser']),
        ...mapState('Sgs', ['user']),
        ...mapState('Jsf', ['showmuinfo', 'muinfo', 'jsflist', 'jsflistmaster']),
        search: {
            get() {
                return this.$store.getters['Jsf/getSearch']
            },
            set(val) {
                this.$store.commit('Jsf/SET_SEARCH', val)
            },
        },
        jsfrequestfor: {
            get() {
                return this.$store.getters['Jsf/getJsfRequestFor']
            },
            set(val) {
                this.$store.commit('Jsf/SET_JSFREQUESTFOR', val)
            },
        },
        jsfrequestforsel: {
            get() {
                return this.$store.getters['Jsf/getJsfRequestForSel']
            },
            set(val) {
                this.$store.commit('Jsf/SET_JSFREQUESTFORSEL', val)
            },
        },
        tabjsfids: {
            get() {
                return this.$store.getters['Jsf/getTabJsfIds']
            },
            set(val) {
                this.$store.commit('Jsf/SET_TABJSFIDS', val)
            },
        },
    },
    watch: {
        jsflistmaster() {
            this.prunejsf()
        },
    },
    methods: {
        cleanfornew() {
            this.$store.dispatch('Jsf/reset', null, { root: true })
        },
        refresh() {
            this.$store.dispatch('Jsf/fetchList', null, { root: true })
        },
        clearstext() {
            localStorage.removeItem('jsfsearch')
            this.artstatsel = 'all'
        },
        prunejsf() {
            let currentjsf = {},
                worklist = []

            this.$store.commit('Jsf/SET_JSFLIST', [])

            if (this.listtab === 'all') {
                this.$store.commit('Jsf/SET_JSFLIST', this.jsflistmaster)
            } else {
                for (let idx = 0; idx < this.tabjsfids[this.listtab].length; idx++) {
                    currentjsf = this.jsflistmaster.find(o => o.jsfid === this.tabjsfids[this.listtab][idx])

                    if (currentjsf.requestfor === this.jsfrequestforsel || this.jsfrequestforsel === 'All') {
                        this.$store.commit('Jsf/PUSH_JSFLIST', currentjsf)
                    }
                }
            }

            if (this.jsfrequestforsel !== 'All') {
                for (let idx = 0; idx < this.jsflist.length; idx++) {
                    currentjsf = this.jsflist[idx]

                    if (currentjsf.requestfor === this.jsfrequestforsel) {
                        worklist.push(currentjsf)
                    }
                }

                this.$store.commit('Jsf/SET_JSFLIST', worklist)
            }

            localStorage.setItem('tabvalue', this.listtab)
            localStorage.setItem('requestfor', this.jsfrequestforsel)
        },
        prunejsfbytab(tabname) {
            this.listtab = tabname
            this.prunejsf()
        },
        displaymuinfo(info) {
            this.$store.commit('Jsf/SET_SHOWMUINFO', true)
            this.$store.commit('Jsf/SET_MUINFO', info)
        },
        closemuinfo() {
            this.$store.commit('Jsf/SET_SHOWMUINFO', false)
            this.$store.commit('Jsf/SET_MUINFO', '')
        },
        modlock(id, curlock) {
            const user = this.$store.getters['Sgs/getUser']
            let path = 'Jsf/'

            if (curlock === 'mdi-lock-open-outline') {
                path += 'Lock'
            } else {
                path += 'Unlock'
            }

            path += '/' + id

            if (user.isadmin) {
                this.$store.dispatch('Jsf/setLock', { path: path }, { root: true })
            }
        },
        runjsfaction(item) {
            switch (item.currentaction) {
                case 'change':
                    this.$router.push({ path: '/edit/jsf/' + item.jsfid })
                    break
                case 'distro':
                    location.href = '/v1/jsf_distro.php?jsfID=' + item.jsfid
                    break
                case 'quote':
                    location.href = '/v1/jsf_quote.php?jsfID=' + item.jsfid
                    break
                case 'print':
                    //this.$router.push({ path: '/print/jsf/' + item.jsfid })
                    //location.href = '/v1/printv2.php?jsfID=' + item.jsfid
                    location.href = '/v1/printv3.php?jsfID=' + item.jsfid
                    break
                case 'pdf':
                    location.href = '/v1/jsf_downloadpdf.php?pdf=' + item.jsfnumber + '.pdf'
                    break
                case 'track':
                    location.href = '/shipping/' + item.jsfnumber
                    break
                default:
                    if (item.currentaction === 'approve' && item.lockicon !== 'mdi-lock-outline') {
                        this.lockitem = item
                        this.asklock = true
                    } else {
                        this.$store.dispatch(
                            'Jsf/modJsfStatus',
                            { jsfid: item.jsfid, jsfnumber: item.jsfnumber, status: item.currentaction },
                            { root: true }
                        )
                    }
                    break
            }
        },
        saveStatusAfterCheck(item) {
            this.asklock = false
            let path = 'Jsf/Lock/' + item.jsfid
            this.$store.dispatch('Jsf/setLock', { path: path }, { root: true }).then(() => {
                this.$store.dispatch(
                    'Jsf/modJsfStatus',
                    { jsfid: item.jsfid, jsfnumber: item.jsfnumber, status: item.currentaction },
                    { root: true }
                )
            })
        },
        saveStatusAfterCancel() {
            this.asklock = false
            this.$store.dispatch('Jsf/fetchList', null, { root: true })
        },
        filterit(what) {
            if (what === 'fromart') {
                if (this.artstatsel === 'all') {
                    this.search = ''
                } else {
                    this.search = this.artstatsel
                }
            } else {
                this.search = what
            }

            localStorage.setItem('jsfsearch', this.search)
        },
        setrowclass(item) {
            let addlclass = ''

            if (item.holdstatus === '1') {
                addlclass = ' onhold'
            }

            if (item.jsfzeropricelist) {
                addlclass = ' needsprice'
            }

            return item.statclass + addlclass
        },
        savetablestate() {
            /* --- have to next tick this because needs to happen after mounted above --- */
            this.$nextTick(() => {
                localStorage.setItem('jsftable', JSON.stringify(this.jsftable))
            })
        },
        gettotalitems(e) {
            this.recsdisplayed = e.itemsLength
        },
    },
}
